import { useField } from 'formik';
import { HTMLInputTypeAttribute, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import clsx from 'clsx';

interface InputProps {
  type: HTMLInputTypeAttribute;
  name: string;
  value?: number | string;
  readOnly?: boolean;
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
  className?: string;
  children?: ReactNode;
  label?: string;
  index?: number;
  required?: boolean;
  disabled?: boolean;
}

function Input({
  type,
  name,
  placeholder,
  readOnly,
  maxLength,
  minLength,
  max,
  min,
  className,
  children,
  label,
  index,
  required,
  disabled,
}: InputProps) {
  const [field, meta] = useField(name);

  return (
    <Form.Group controlId={index ? `${name}_${index}` : name} className={className}>
      <div>
        {label && (
          <Form.Label>
            {label}
            {required && <span className="required-asterisk"> *</span>}
          </Form.Label>
        )}
        <Form.Control
          type={type}
          className={clsx(meta.error && meta.touched && 'input-error')}
          {...field}
          placeholder={placeholder}
          maxLength={maxLength}
          minLength={minLength}
          max={max}
          min={min}
          readOnly={readOnly}
          aria-describedby={index ? `${name}_${index}_error` : `${name}_error`}
          disabled={disabled}
        />
        {children}
      </div>
      {meta.error && meta.touched && (
        <Form.Text
          id={index ? `${name}_${index}_error` : `${name}_error`}
          className={clsx('text-error', meta.error.length > 45 && 'long-error')}
        >
          {meta.error}
        </Form.Text>
      )}
    </Form.Group>
  );
}

export default Input;
